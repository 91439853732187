var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Your Name"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"email","placeholder":"Your Email"},model:{value:(_vm.emailValue),callback:function ($$v) {_vm.emailValue=$$v},expression:"emailValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password","vid":"Password","rules":"required|password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false:null,"placeholder":"Your Password"},model:{value:(_vm.PasswordValue),callback:function ($$v) {_vm.PasswordValue=$$v},expression:"PasswordValue"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Password Confirm","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"type":"password","placeholder":"Confirm Password"},model:{value:(_vm.passwordCon),callback:function ($$v) {_vm.passwordCon=$$v},expression:"passwordCon"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-card',{staticClass:"border mt-1",attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"p-1"},[_c('b-card-title',{staticClass:"font-medium-2"},[_c('feather-icon',{attrs:{"icon":"LockIcon","size":"18"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Permission")])],1)],1),_c('b-table',{staticClass:"mb-0",attrs:{"striped":"","responsive":"","items":_vm.permissionsData},scopedSlots:_vm._u([{key:"cell(module)",fn:function(data){return [_vm._v(" "+_vm._s(data.value)+" ")]}},{key:"cell()",fn:function(data){return [_c('b-form-checkbox',{attrs:{"checked":data.value}})]}}])})],1),_c('b-col',[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }